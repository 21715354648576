import * as React from 'react';
import { SelectInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import countryList from '../utils/countryList';

const countries = countryList.map((country) => ({
    id: country.countryShortCode,
    name: country.countryName,
}));

export const CountryInput = (props) => {
    return <SelectInput choices={countries} {...props} />;
};

export const RegionInput = (props) => {
    const { values } = useFormState();

    const country = countryList.find((c) => c.countryShortCode === values.countryCode);
    const regions = country
        ? country?.regions.map((region) => ({
              id: region.shortCode,
              name: region.name,
          }))
        : [];

    return <SelectInput choices={regions} {...props} />;
};
