import { stringify } from 'querystring';
import { DataProvider, fetchUtils } from 'ra-core';

/**
 * @see https://github.com/nestjsx/crud
 */
/*
const countDiff = (o1: Record<string, any>, o2: Record<string, any>): Record<string, any> => omitBy(o1, (v, k) => o2[k] === v);

const composeFilter = (paramsFilter: any): QueryFilter[] => {
    const flatFilter = fetchUtils.flattenObject(paramsFilter);
    return Object.keys(flatFilter).map((key) => {
        const splitKey = key.split('||');

        let field = splitKey[0];
        let ops = splitKey[1];
        if (!ops) {
            if (typeof flatFilter[key] === 'boolean' || typeof flatFilter[key] === 'number' || (typeof flatFilter[key] === 'string' && flatFilter[key].match(/^\d+$/))) {
                ops = CondOperator.EQUALS;
            } else {
                ops = CondOperator.CONTAINS;
            }
        }

        if (field.startsWith('_') && field.includes('.')) {
            field = field.split(/\.(.+)/)[1];
        }

        return { field, operator: ops, value: flatFilter[key] } as QueryFilter;
    });
};
*/

function composeQuery(params: any) {
    let page = 1;
    let perPage = 10;
    if (params.pagination) {
        page = params.pagination.page || page;
        perPage = params.pagination.perPage || perPage;
    }
    const { q: queryParams, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);

    let encodedQueryFilter = '';
    encodedQueryFilter += `limit=${perPage}`;
    encodedQueryFilter += `&skip=${(page - 1) * perPage}`;

    if (params.sort) {
        encodedQueryFilter += `&sort=${params.sort.order === 'DESC' ? '-' : ''}${params.sort.field || 'id'}`;
    }

    const flatFilter = fetchUtils.flattenObject(filter);
    Object.keys(flatFilter).forEach((key) => {
        const splitKey = key.split('||');

        let field = splitKey[0];
        let ops = splitKey[1] || '=';
        const value = flatFilter[key];

        if (field.startsWith('_') && field.includes('.')) {
            field = field.split(/\.(.+)/)[1];
        }

        encodedQueryFilter += `&${field}${ops}${value}`;
    });

    return mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
}

const composeQueryParams = (queryParams: any = {}): string => {
    return stringify(fetchUtils.flattenObject(queryParams));
};

const mergeEncodedQueries = (...encodedQueries) => encodedQueries.map((query) => query).join('&');

function createBody(data: any) {
    let hasFile = false;
    for (const key in data) {
        let value = data[key];

        if (value.rawFile) {
            hasFile = true;
            break;
        }

        if (Array.isArray(value)) {
            for (const v of value) {
                if (v.rawFile) {
                    hasFile = true;
                    break;
                }
            }
        }

        // entity has urls
        if (key.endsWith('URL') || key.endsWith('URLs')) {
            hasFile = true;
            break;
        }
    }

    let body: any;
    if (hasFile) {
        body = new FormData();

        for (const key in data) {
            const value = data[key];

            // check if it's an array of files
            let isArrayOfFiles = false;
            if (key.endsWith('URLs')) isArrayOfFiles = true;
            if (Array.isArray(value)) {
                for (const v of value) {
                    if (v.rawFile) {
                        isArrayOfFiles = true;
                        break;
                    }
                }
            }

            // array of files
            if (isArrayOfFiles) {
                for (let i = 0; i < value.length; i++) {
                    const file = value[i];

                    // body.append(`${key}_${i + 1}`, file.rawFile);
                    // body.append(`${key}_filename_${i + 1}`, file.title);

                    if (file.rawFile) {
                        body.append(key, file.rawFile);
                        body.append(`${key}_filename`, '#raw#');
                    } else {
                        body.append(key, file);
                        body.append(`${key}_filename`, file);
                    }
                }
            } else if (value.rawFile) {
                // single file
                body.append(key, value.rawFile);
                body.append(`${key}_filename`, value.title);
            } else {
                body.append(key, value);
            }
        }
    } else {
        body = JSON.stringify(data);
    }

    return body;
}

const provider = (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
    getList: async (resource, params) => {
        // fetch gift list from credits
        const query = composeQuery(params);
        const url = `${apiUrl}/${resource}?${query}`;

        let { json, headers } = await httpClient(url)

        return {
            data: (()=>{
                let data = json.map((d) => {
                    if(resource==='song'){
                        d.artist_title = `${d.artist} ${d.title}`
                    }
                    d.q = JSON.stringify(d);
                    return d;
                })
                if(resource==='song' && params.sort?.field==='artist_title'){
                    const base = params.sort.order==='ASC'?1:-1
                    data.sort((a,b)=>a.artist_title>b.artist_title?base:-base)
                }
                return data
            })(),
            total: parseInt((headers.get('content-range') || '/').split('/').pop() || '', 10)
        }
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = composeQuery(params);
        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const query = composeQuery(params);
        const url = `${apiUrl}/${resource}?${query}`;

        // return httpClient(url).then(({ json }) => ({
        //     data: json.data,
        //     total: json.total,
        // }));
        return httpClient(url).then(({ json, headers }) => ({
            // data: json.data,
            data: json.map((d) => {
                d.q = JSON.stringify(d);
                return d;
            }),
            total: parseInt((headers.get('content-range') || '/').split('/').pop() || '', 10),
        }));
    },

    update: (resource, params) => {
        // no need to send all fields, only updated fields are enough
        // const data = countDiff(params.data, params.previousData);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PATCH',
            body: createBody(params.data),
            // }).then(({ json }) => ({ data: json }));
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },

    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then((responses) => ({
            data: responses.map(({ json }) => json),
        })),

    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: createBody(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },

    createMany: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/many`, {
            method: 'POST',
            body: createBody(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: { ...json, id: params.id } })),

    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then((responses) => ({ data: responses.map(({ json }) => json) })),
});

export default provider;
