import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import { lighten, darken } from './utils/colorutils';

const primary = '#534cda';
const secondary = '#373737';

const theme = merge({}, defaultTheme, {
    palette: {
        primary: {
            main: primary,
            light: lighten(primary, 10),
            dark: darken(primary, 10),
        },
        secondary: {
            main: secondary,
            light: lighten(secondary, 10),
            dark: darken(secondary, 10),
        },
    },
});

export default theme;
