import * as React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, Create, SimpleForm, TextInput, Edit, required, TopToolbar } from 'react-admin';

import { ImportButton } from 'react-admin-import-csv';
import { CreateButton, ExportButton } from 'ra-ui-materialui';

function transformRows(csvRows: any[]) {
    for (const row of csvRows) {
        delete row.id;
        delete row._id;
        delete row.password;
        delete row.q;
    }

    return csvRows;
}

const ListActions = (props) => {
    const { className, basePath } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath} />
            <ExportButton />
            <ImportButton {...props} transformRows={transformRows} />
        </TopToolbar>
    );
};

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const TranslationList = (props) => (
    <List {...props} filters={userFilters} actions={<ListActions />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="lang_en" />
            <TextField source="lang_fr" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const TranslationEdit = (props) => (
    <div>
        <Edit {...props} mutationMode="optimistic">
            <SimpleForm redirect="list">
                <TextInput source="name" validate={required()} />
                <TextInput source="lang_en" validate={required()} options={{ multiline: true, rows:5 }} />
                <TextInput source="lang_fr" validate={required()} options={{ multiline: true, rows:5 }} />
            </SimpleForm>
        </Edit>
        <b>&lt;b&gt;Gras&lt;/b&gt;</b>
        <br/>
        &lt;br/&gt; Saut à la ligne
    </div>

);

export const TranslationCreate = (props) => (
    <div>
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" validate={required()} />
                <TextInput source="lang_en" validate={required()} options={{ multiline: true, rows:5 }} />
                <TextInput source="lang_fr" validate={required()} options={{ multiline: true, rows:5 }} />
            </SimpleForm>
        </Create>
        <b>&lt;b&gt;Gras&lt;/b&gt;</b>
        <br/>
        &lt;br/&gt; Saut à la ligne
    </div>

);
