import * as React from 'react';
import { Create, Datagrid, DeleteButton, Edit, EditButton, FileField, FileInput, List, required, SimpleForm, TextField, TextInput, useRecordContext } from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

const PreviewMedia = ({ record = null, source, title }: any) => {
    if (typeof record == 'string') {
        record = {
            [source]: record + `?=${Math.random()}`,
        };
    }

    if (record && record.rawFile) title = record.rawFile.name;

    return <FileField record={record} source={source} title={title} target="_blank" />;
};

const MediaInput = (props) => {
    const record = useRecordContext(props);

    const title = record.originalFilename;

    return (
        <FileInput source={props.source} label="media" validate={required()}>
            <PreviewMedia source="src" title={title} />
        </FileInput>
    );
};

export const MediaList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="originalFilename" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const MediaEdit = (props) => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm redirect="list">
            <MediaInput source="mediaURL" />
        </SimpleForm>
    </Edit>
);

export const MediaCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <FileInput source="mediaURL" label="media" validate={required()}>
                {/* <FileField source="src" title="title" /> */}
                <PreviewMedia source="src" />
            </FileInput>
        </SimpleForm>
    </Create>
);
