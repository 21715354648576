import { Create, Datagrid, DeleteButton, Edit, EditButton, FunctionField, List, ReferenceField, ReferenceInput, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const InterludeList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'category_id', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <ReferenceField label="Category" source="category_id" reference="interludecategory">
                <FunctionField render={(record) => record!.name} />
            </ReferenceField>
            <TextField source="name" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const InterludeEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
            <TextInput source="video" validate={required()} />
            <ReferenceInput label="Category" source="category_id" reference="interludecategory">
                <SelectInput optionText={(record) => `${record.name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const InterludeCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
            <TextInput source="video" validate={required()} />
            <ReferenceInput label="Category" source="category_id" reference="interludecategory">
                <SelectInput optionText={(record) => `${record.name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
