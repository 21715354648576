import polyglotI18nProvider from 'ra-i18n-polyglot';
import ENi18n from 'ra-language-english';
import {Admin, fetchUtils, Resource} from 'react-admin';
import apiURL from './apiURL';
import {EventCreate, EventEdit, EventList} from './pages/events';
import {GiftCreate, GiftList} from './pages/gifts';
import {InterludeCategoryCreate, InterludeCategoryEdit, InterludeCategoryList} from './pages/interludeCategories';
import {InterludeCreate, InterludeEdit, InterludeList} from './pages/interludes';
import {MediaCreate, MediaEdit, MediaList} from './pages/medias';
import {MedleyCreate, MedleyEdit, MedleyList} from './pages/medleys';
import {PlaceCreate, PlaceEdit, PlaceList} from './pages/places';
import {SongGenreCreate, SongGenreEdit, SongGenreList} from './pages/songgenres';
import {SongLangCreate, SongLangEdit, SongLangList} from './pages/songlangs';
import {SongRegionCreate, SongRegionEdit, SongRegionList} from './pages/songregions';
import {SongCreate, SongEdit, SongList} from './pages/songs';
import {TranslationCreate, TranslationEdit, TranslationList} from './pages/translations';
// pages
import {UserCreate, UserEdit, UserList} from './pages/users';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import theme from './theme';


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('auth') || '';
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const i18nProvider = polyglotI18nProvider(() => ENi18n, 'en', {allowMissing: true, onMissingKey: (key, _, __) => key});

const myDataProvider = dataProvider(apiURL, httpClient);

const App = () => (
    <Admin dataProvider={myDataProvider} authProvider={authProvider} i18nProvider={i18nProvider} theme={theme}>
        <Resource name="user" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="song" list={SongList} edit={SongEdit} create={SongCreate} />
        <Resource name="songgenre" list={SongGenreList} edit={SongGenreEdit} create={SongGenreCreate} options={{label: 'Songs Genres'}} />
        <Resource name="songregion" list={SongRegionList} edit={SongRegionEdit} create={SongRegionCreate} options={{label: 'Songs Regions'}} />
        <Resource name="songlang" list={SongLangList} edit={SongLangEdit} create={SongLangCreate} options={{label: 'Songs Langs'}} />
        <Resource name="interludecategory" list={InterludeCategoryList} edit={InterludeCategoryEdit} create={InterludeCategoryCreate} options={{label: 'Interlude Categories'}} />
        <Resource name="interlude" list={InterludeList} edit={InterludeEdit} create={InterludeCreate} />
        <Resource name="medley" list={MedleyList} edit={MedleyEdit} create={MedleyCreate} />
        <Resource name="place" list={PlaceList} edit={PlaceEdit} create={PlaceCreate} />
        <Resource name="event" list={EventList} edit={EventEdit} create={EventCreate} />
        <Resource name="media" list={MediaList} edit={MediaEdit} create={MediaCreate} />
        <Resource name="gift" list={GiftList} create={GiftCreate} />
        <Resource name="translation" list={TranslationList} edit={TranslationEdit} create={TranslationCreate} />
    </Admin>
);

export default App;
