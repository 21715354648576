import { AutocompleteInput, Create, Datagrid, DeleteButton, FunctionField, List, NumberField, NumberInput, ReferenceField, ReferenceInput, required, SimpleForm, TextField, TextInput } from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const GiftList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'date_created', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <ReferenceField label="Owner" source="owner_id" reference="user">
                <FunctionField render={(record) => record!.email} />
            </ReferenceField>
            <NumberField source="pack_count" />
            <TextField source="unit_count" />
            <TextField source="duration" />
            <ReferenceField label="Gifter" source="gifter_id" reference="user">
                <FunctionField render={(record) => record!.email} />
            </ReferenceField>
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const GiftCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Owner" source="owner_id" reference="user" validate={required()} perPage={10_000_000} >
                <AutocompleteInput optionText={(record) => `${record?.email}`} translateChoice={false} />
            </ReferenceInput>
            <NumberInput source="pack_count" min={0} validate={required()} />
            <NumberInput source="unit_count" min={0} validate={required()} />
            <NumberInput source="duration" min={0} validate={required()} />
        </SimpleForm>
    </Create>
);
