import { Create, Datagrid, DateField, DateTimeInput, DeleteButton, Edit, EditButton, FunctionField, ImageField, ImageInput, List, NumberInput, ReferenceField, ReferenceInput, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';

const placeFilters = [<TextInput source="all" label="Search" alwaysOn />];

const PreviewImage = ({ record = null, source }: any) => {
    if (typeof record == 'string') {
        record = {
            [source]: record + `?=${Math.random()}`,
        };
    }
    return <ImageField record={record} source={source} />;
};

export const EventList = (props) => (
    <List {...props} filters={placeFilters} bulkActionButtons={false} sort={{ field: 'date', order: 'DESC' }} perPage={200} exporter={false}>
        <Datagrid>
            <ReferenceField label="Owner" source="owner_id" reference="user">
                <FunctionField render={(record) => record!.email} />
            </ReferenceField>
            <TextField source="name" />
            <DateField source="date" showTime />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const EventEdit = (props) => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm redirect="list">
            <ReferenceField label="Place" source="place_id" reference="place">
                <FunctionField render={(record) => `${record!.name}`} />
            </ReferenceField>
            <TextInput source="name" validate={required()} />
            <DateTimeInput source="date" validate={required()} />
            <TextInput source="desc" validate={required()} />
            <NumberInput source="teams" validate={required()} label={'Number of teams'} />
            {/* <ArrayInput source="teams">
                <SimpleFormIterator>
                    <TextInput source="" key={Math.random() + ''} />
                </SimpleFormIterator>
            </ArrayInput> */}
            {/* @ts-ignore */}
            <ImageInput source="imageURL" accept="image/*">
                <PreviewImage source="src" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const EventCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Place" source="place_id" reference="place" validate={required()}>
                <SelectInput optionText={(record) => `${record.name}`} />
            </ReferenceInput>
            <TextInput source="name" validate={required()} />
            <DateTimeInput source="date" validate={required()} />
            <TextInput source="desc" validate={required()} />
            <NumberInput source="teams" validate={required()} label={'Number of teams'} />
            {/* <ArrayInput source="teams">
                <SimpleFormIterator>
                    <TextInput source="" key={Math.random() + ''} />
                </SimpleFormIterator>
            </ArrayInput> */}
            {/* @ts-ignore */}
            <ImageInput source="imageURL" accept="image/*">
                <PreviewImage source="src" />
            </ImageInput>
        </SimpleForm>
    </Create>
);
