import * as React from 'react';
import { Create, Datagrid, DeleteButton, Edit, EditButton, FunctionField, ImageField, ImageInput, List, NumberInput, ReferenceField, ReferenceInput, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';
import { CountryInput, RegionInput } from '../components/countryRegion';

const placeFilters = [<TextInput source="all" label="Search" alwaysOn />];

const PreviewImage = ({ record = null, source }: any) => {
    if (typeof record == 'string') {
        record = {
            [source]: record + `?=${Math.random()}`,
        };
    }
    return <ImageField record={record} source={source} />;
};

export const PlaceList = (props) => (
    <List {...props} filters={placeFilters} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="address" />
            <TextField source="zipcode" />
            <TextField source="city" />
            <TextField source="countryCode" />
            <TextField source="regionCode" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const PlaceEdit = (props) => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm redirect="list">
            <ReferenceField label="Owner" source="owner_id" reference="user">
                <FunctionField render={(record) => `${record.firstname} ${record.lastname}`} />
            </ReferenceField>
            <TextInput source="name" validate={required()} />
            <TextInput source="desc" validate={required()} />
            <TextInput source="telephone" validate={required()} />
            <TextInput source="address" validate={required()} />
            <TextInput source="zipcode" validate={required()} />
            <TextInput source="city" validate={required()} />
            <CountryInput validate={required()} source="countryCode" />
            <RegionInput validate={required()} source="regionCode" />
            <NumberInput source="lat" validate={required()} label={"Latitude"} />
            <NumberInput source="lng" validate={required()} label={"Longitude"} />
            {/* @ts-ignore */}
            <ImageInput source="imageURL" accept="image/*">
                <PreviewImage source="src" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const PlaceCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Owner" source="owner_id" reference="user" validate={required()}>
                <SelectInput optionText={(record) => `${record.firstname} ${record.lastname}`} />
            </ReferenceInput>
            <TextInput source="name" validate={required()} />
            <TextInput source="desc" validate={required()} />
            <TextInput source="telephone" validate={required()} />
            <TextInput source="address" validate={required()} />
            <TextInput source="zipcode" validate={required()} />
            <TextInput source="city" validate={required()} />
            <CountryInput validate={required()} source="countryCode" />
            <RegionInput validate={required()} source="regionCode" />
            <NumberInput source="lat" validate={required()} label={"Latitude"} />
            <NumberInput source="lng" validate={required()} label={"Longitude"} />
            {/* @ts-ignore */}
            <ImageInput source="imageURL" accept="image/*">
                <PreviewImage source="src" />
            </ImageInput>
        </SimpleForm>
    </Create>
);
