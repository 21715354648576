import {
    BooleanInput,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    ImageField,
    ImageInput,
    List,
    Pagination,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

const PreviewImage = ({ record = null, source }: any) => {
    if (typeof record == 'string') {
        record = {
            [source]: record + `?=${Math.random()}`,
        };
    }
    return <ImageField record={record} source={source} />;
};

export const SongList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'artist_title', order: 'ASC' }} perPage={100} exporter={false}>
        <Datagrid>
            <TextField source="artist_title" />
            <TextField source="groupType" />
            <TextField source="voiceType" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const SongEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" sanitizeEmptyValues={false}>
            <TextInput source="artist" validate={required()} />
            <TextInput source="title" validate={required()} />
            <TextInput source="preview" validate={required()} />
            <TextInput source="video" validate={required()} />
            <TextInput source="description" label={'Tags'} />
            <TextInput source="composer_credit" label={'Crédit compositeur'}  />
            <TextInput source="composer_credit2" label={'Crédit auteur'}  />
            <TextInput source="iswc"  />
            <TextInput source="sacem_title"  />

            <BooleanInput source="is_demo" />
            <BooleanInput source="published" />
            <SelectInput
                validate={required()}
                source="promote"
                choices={[
                    { id: 'none', name: 'No promotion' },
                    { id: 'new', name: 'New' },
                    { id: 'popular', name: 'Popular' },
                ]}
            />
            <ReferenceArrayInput source="songgenre_ids" reference="songgenre" allowEmpty>
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="songregion_ids" reference="songregion" allowEmpty>
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="songlang_ids" reference="songlang" allowEmpty>
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <SelectInput
                validate={required()}
                source="groupType"
                choices={[
                    { id: 'solo', name: 'Solo' },
                    { id: 'duo', name: 'Duo' },
                    { id: 'group', name: 'Group' },
                ]}
            />
            <SelectInput
                validate={required()}
                source="voiceType"
                choices={[
                    { id: 'male', name: 'Masculine' },
                    { id: 'female', name: 'Feminine' },
                    { id: 'both', name: 'Both' },
                ]}
            />
            <ImageInput source="imageURL" accept="image/*">
                {/* <ImageField source="src" title="title" /> */}
                <PreviewImage source="src" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const SongCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="artist" validate={required()} />
            <TextInput source="title" validate={required()} />
            <TextInput source="preview" validate={required()} />
            <TextInput source="video" validate={required()} />
            <TextInput source="description" label={'Tags'} />
            <TextInput source="composer_credit" label={'Crédit compositeur'}  />
            <TextInput source="composer_credit2"  label={'Crédit auteur'}  />
            <TextInput source="iswc"  />
            <TextInput source="sacem_title"   />
            <BooleanInput source="published" validate={required()} defaultValue={false} />
            <BooleanInput source="is_demo" validate={required()} defaultValue={false} />
            <SelectInput
                validate={required()}
                source="promote"
                choices={[
                    { id: 'none', name: 'No promotion' },
                    { id: 'new', name: 'New' },
                    { id: 'popular', name: 'Popular' },
                ]}
            />
            <ReferenceArrayInput source="songgenre_ids" reference="songgenre" allowEmpty label="Genre">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="songregion_ids" reference="songregion" allowEmpty label="Region">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="songlang_ids" reference="songlang" allowEmpty label="Lang">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <SelectInput
                validate={required()}
                source="groupType"
                choices={[
                    { id: 'solo', name: 'Solo' },
                    { id: 'duo', name: 'Duo' },
                    { id: 'group', name: 'Group' },
                ]}
            />
            <SelectInput
                validate={required()}
                source="voiceType"
                choices={[
                    { id: 'male', name: 'Masculine' },
                    { id: 'female', name: 'Feminine' },
                    { id: 'both', name: 'Both' },
                ]}
            />
            <ImageInput source="imageURL" accept="image/*">
                <PreviewImage source="src" />
            </ImageInput>
        </SimpleForm>
    </Create>
);
