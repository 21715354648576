import {
    ArrayInput,
    BooleanInput,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    List,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput
} from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const MedleyList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="name" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const MedleyEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
            <TextInput source="video_plain" validate={required()} />
            <TextInput source="video_off" validate={required()} />
            <TextInput source="video_off_instructions" validate={required()} />
            <BooleanInput source="is_demo" validate={required()} defaultValue={false} />
            <ArrayInput source="tracks">
                <SimpleFormIterator>
                    <TextInput source="" key={Math.random() + ''} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const MedleyCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
            <TextInput source="video_plain" validate={required()} />
            <TextInput source="video_off" validate={required()} />
            <TextInput source="video_off_instructions" validate={required()} />
            <BooleanInput source="is_demo" validate={required()} defaultValue={false} />
            <ArrayInput source="tracks">
                <SimpleFormIterator>
                    <TextInput source="" key={Math.random() + ''} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
