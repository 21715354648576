import * as React from 'react';
import { Create, Datagrid, DeleteButton, Edit, EditButton, EmailField, List, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';
import { CountryInput, RegionInput } from '../components/countryRegion';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const UserList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'email', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="role" />
            <TextField source="status" />
            <EmailField source="email" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="address" />
            <TextField source="zipcode" />
            <TextField source="city" />
            <TextField source="countryCode" />
            <TextField source="regionCode" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const UserEdit = (props) => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm redirect="list">
            <SelectInput
                validate={required()}
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                    { id: 'email', name: 'email' },
                ]}
            />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="firstname" validate={required()} />
            <TextInput source="lastname" validate={required()} />
            <TextInput source="address" validate={required()} />
            <TextInput source="zipcode" validate={required()} />
            <TextInput source="city" validate={required()} />
            <CountryInput validate={required()} source="countryCode" />
            <RegionInput validate={required()} source="regionCode" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <SelectInput
                validate={required()}
                source="role"
                choices={[
                    { id: 'basic', name: 'basic' },
                    { id: 'admin', name: 'admin' },
                ]}
            />
            <SelectInput
                validate={required()}
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                    { id: 'email', name: 'email' },
                ]}
            />
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="firstname" validate={required()} />
            <TextInput source="lastname" validate={required()} />
            <TextInput source="password" validate={required()} />
            <TextInput source="address" validate={required()} />
            <TextInput source="zipcode" validate={required()} />
            <TextInput source="city" validate={required()} />
            <CountryInput validate={required()} source="countryCode" />
            <RegionInput validate={required()} source="regionCode" />
        </SimpleForm>
    </Create>
);
