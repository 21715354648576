import * as React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, Create, SimpleForm, TextInput, Edit, required } from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const SongGenreList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="name" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const SongGenreEdit = (props) => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
        </SimpleForm>
    </Edit>
);

export const SongGenreCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
        </SimpleForm>
    </Create>
);
