import apiURL from '../apiURL';

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${apiURL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                console.log(response);

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                localStorage.setItem('auth', json.access_token);
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
        // const request = new Request('http://localhost:3000/auth/check', {
        //     method: 'POST',
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        // });
        // return fetch(request);
    },
    getPermissions: () => {
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    // getIdentity: () => {
    //     try {
    //         const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth') || '');
    //         return Promise.resolve({ id, fullName, avatar });
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // },
};

export default authProvider;
