import {
    BooleanInput,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    List,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

const userFilters = [<TextInput source="all" label="Search" alwaysOn />];

export const InterludeCategoryList = (props) => (
    <List {...props} filters={userFilters} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} perPage={200} exporter={false}>
        <Datagrid>
            <TextField source="name" />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const InterludeCategoryEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
            <BooleanInput source="is_demo" validate={required()} defaultValue={false} />
        </SimpleForm>
    </Edit>
);

export const InterludeCategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={required()} />
            <BooleanInput source="is_demo" validate={required()} defaultValue={false} />
        </SimpleForm>
    </Create>
);
